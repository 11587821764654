<template>
  <div class="report-form__item">
    <h3>{{ $t('REPORT_ACCUSED_QUESTION') }}</h3>
    <h4>{{ $t('REPORT_ACCUSED_TIP') }}</h4>

    <div class="report-form__item--input mt-15">
      <div
        v-for="(item, idx) in accused"
        :key="idx"
        class="report-form__item--input autocomplete"
      >
        <input
          :value="item.name"
          type="text"
          name="accused"
          :placeholder="$t('REPORT_ACCUSED_PLACEHOLDER')"
          autocomplete="off"
          disabled
        />
        <span class="del-btn" @click="removeField(idx)">x</span>
      </div>
    </div>

    <slot />

    <Autocomplete
      v-model="search"
      :options="options"
      value-key="id"
      :placeholder="$t('TYPE_TO_SEARCH')"
      @select="onSelect"
    >
      <template slot="autocomplete-label" slot-scope="{ item }">
        {{ item.name ? item.name : item }}
        <i>({{ item.email ? item.email : $t('other_person') }})</i>
      </template>
    </Autocomplete>

    <div v-if="isConnectEnabled">
      <p class="report-form__connect-info">
        {{ $t('REPORT_ACCUSED_CONNECT_INFO') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Autocomplete: () => import('../Autocomplete.vue'),
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: '',
      accused: this.value,
      options: [],
    }
  },

  computed: mapGetters(['isConnectEnabled']),

  watch: {
    search() {
      this.searchColaborators(this.search)
    },

    accused() {
      this.$emit(
        'input',
        this.accused.map((item) => item.id),
      )
      this.$emit('track', 'accused')
    },
  },

  methods: {
    ...mapActions(['getEmployees']),

    removeField(field) {
      this.accused.splice(field, 1)
    },

    async searchColaborators(searchTerm) {
      this.options = new Promise((resolve) => {
        setTimeout(async () => {
          const term = searchTerm?.trim()?.toLowerCase()

          if (!term) {
            resolve([])
          } else {
            const employees = await this.getEmployees({ term })
            resolve([...employees, term])
          }
        }, 500)
      })
    },

    onSelect(selected) {
      if (!selected?.name) {
        this.$emit('selectedCustomIvolved', selected)
        return
      }

      this.accused.push(selected)
    },
  },
}
</script>
